
	import { defineComponent } from 'vue';
	import Github from '@/components/icons/Github.vue';
	import Linkedin from '@/components/icons/Linkedin.vue';
	import Twitter from '@/components/icons/Twitter.vue';
	import Gmail from '@/components/icons/Gmail.vue';

	export default defineComponent({
		name: 'Plugs',
		components: {
			Github,
			Linkedin,
			Twitter,
			Gmail,
		},
		setup() {
			const onClick = (path: string) => {
				window.open(path, '_blank');
			};
			return { onClick };
		},
	});
