
	import { defineComponent, PropType } from 'vue'

	export interface CardInfo {
		title: string
		subtitle: string
		year: number
		month: number
		about: string
		imageUrls: string[]
		tags: string[]
		links: [string, string][]
	}

	const getContentWidth = (): number => {
		// Getting image width from HTML Element seems to be unreliable,
		// So falling back on manually set hard coded values is definitely,
		// less ideal but actually works... so... web dev is fun.
		if (window.innerWidth >= 1080) return 800
		if (window.innerWidth >= 620) return 600
		else return Math.round(window.innerWidth * 0.9)
	}

	export default defineComponent({
		name: 'PortfolioCard',
		props: {
			info: {
				required: true,
				type: Object as PropType<CardInfo>,
			},
		},
		setup(props) {
			const months = [
				'JAN',
				'FEB',
				'MAR',
				'APR',
				'MAY',
				'JUN',
				'JUL',
				'AUG',
				'SEP',
				'OCT',
				'NOV',
				'DEC',
			]

			const hasLinks = (info: CardInfo) => {
				return info.links && info.links.length > 0
			}

			const hasMultipleImages = (info: CardInfo) => {
				return info.imageUrls && info.imageUrls.length > 1
			}

			const isMovie = (image: string): boolean => {
				const ext = image.split('.')[1]
				return ext.toLowerCase() === 'mp4'
			}

			const isLink = (image: string): boolean => {
				const prefix = image.split(':')[0]
				return (
					prefix.toLowerCase() === 'https' || prefix.toLowerCase() === 'http'
				)
			}

			const genId = (value: string): string => {
				let hash = 0
				if (value.length === 0) return `${hash}`
				for (let i = 0; i < value.length; i++) {
					const chr = value.charCodeAt(i)
					hash = (hash << 5) - hash + chr
					hash |= 0
				}
				return `${hash}`
			}

			const genHref = (value: string): string => {
				return `#${genId(value)}`
			}

			const onClick = (path: string): void => {
				window.open(path, '_blank')
			}

			const monthToDate = (month: number): string => {
				return months[month - 1]
			}

			const getEmptyArray = (): [] => {
				return []
			}

			const getContentHeight = (): number => {
				const width = getContentWidth()
				return Math.round((width / 16) * 9)
			}

			return {
				hasLinks,
				isMovie,
				hasMultipleImages,
				isLink,
				genId,
				genHref,
				onClick,
				monthToDate,
				getEmptyArray,
				getContentWidth,
				getContentHeight,
			}
		},
		mounted() {
			const scrolled = this.$refs.carousel as HTMLElement
			const buttons = this.$refs.buttons as HTMLElement
			if (scrolled && buttons) {
				const anchors = buttons.querySelectorAll<HTMLElement>("a[href^='#']")
				// hash tag anchor href starts with '#'
				anchors.forEach((a: HTMLElement) => {
					a.onclick = function (e: Event) {
						e.preventDefault()
						const href = a.getAttribute('href')!.slice(1) // remove initial '#'
						const target = scrolled.querySelector<HTMLElement>(`[id='${href}']`)

						if (target && target.offsetLeft) {
							const cw = getContentWidth()
							scrolled.scrollLeft = Math.floor(target.offsetLeft / cw) * cw
						}
					}
				})
			}
		},
	})
