
	import { Vue } from 'vue-class-component';

	export default class NavBar extends Vue {
		private collapsed = false;
		beforeMount(): void {
			this.$router.beforeEach((to, from, next) => {
				console.log(`Before Route ${from.path} -> ${to.path}`);
				if (to.path === '/') {
					this.collapsed = false;
					setTimeout(next, 1000);
				} else {
					if (from.path === '/') {
						next();
					} else {
						this.collapsed = false;
						setTimeout(() => {
							next();
							this.collapsed = true;
						}, 1000);
					}
				}
			});
		}
		onClick(item: string): void {
			if (item === 'portfolio') {
				this.$router.push('portfolio');
				this.collapsed = true;
			} else if (item === 'plugs') {
				this.$router.push('plugs');
				this.collapsed = true;
			} else if (item === 'resume') {
				window.open(`${process.env.BASE_URL}resume-josh-hess.pdf`);
			} else if (item === 'nav-bar') {
				this.$router.push('/');
			}
		}
	}
