
	import { Options, Vue } from 'vue-class-component'
	import PortfolioCard, {
		CardInfo,
	} from '../components/portfolio/PortfolioCard.vue'
	import Info from '../assets/portfolio/info.json'

	@Options({
		components: {
			PortfolioCard,
		},
	})
	export default class Projects extends Vue {
		private currentYear = 2021
		private cardInfo!: CardInfo[]

		beforeMount(): void {
			this.cardInfo = Info.map((i) => {
				return {
					title: i.title,
					subtitle: i.subtitle,
					year: i.year,
					month: i.month,
					about: i.about,
					imageUrls: i.image ? i.image : [],
					tags: i.tags ? i.tags : [],
					links: i.links ? i.links : [],
				} as CardInfo
			})
		}

		getProjectYears(): number[] {
			const years = new Set(Info.map((i) => i.year))
			return [...years].reverse()
		}

		getProjectInfo(): CardInfo[] {
			return this.cardInfo
				.filter((i) => i.year === this.currentYear)
				.sort((i1, i2) => i2.month - i1.month)
		}

		isYearActive(year: number): boolean {
			return year === this.currentYear
		}

		onYearClick(year: number): void {
			this.currentYear = year
			const scrollable = this.$refs.scrollable as any
			scrollable.scrollTop = 0
		}
	}
